
import "./assets/css/slick.css"
import "./assets/css/magnific-popup.css"
//import "./assets/css/font-awesome.min.css"
//import "./assets/css/flaticon.css"
import "./assets/css/default.css"
import "./assets/css/animate.min.css"
import "./assets/css/odometer.min.css"
import "./assets/css/bootstrap.min.css"
import "./assets/css/style.css"
import "./style.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavbarComponent from "./components/NavbarComponent";
import HomeComponent from "./components/HomeComponent";
import FooterComponent from "./components/FooterComponent";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import CreateProjectComponent from "./components/CreateProjectComponent";
import ConnectWallet from "./components/ConnectWallet";
import DiscoverComponent from "./components/DiscoverComponent";
import ProjectComponent from "./components/ProjectComponent";
import ProfileComponent from "./components/ProfileComponent";
import ProjectNoticeComponent from "./components/ProjectNoticeComponent.jsx";
import { useState } from "react";
import { ethers } from "ethers";
import { abi } from "./abi";
//const CONTRACT_ADDRESS = "0xca4732ea381ECBa0085F8c8b3Bc34038935Df214"; first bnb contract version1
//export const CONTRACT_ADDRESS = "0x49a4afeB7d47CB863C7Ae8005885213E56438Ab9";//bnb contract version2
export const CONTRACT_ADDRESS = '0x32c2E3e17b42C4FAb68D502e6B2b1F3455bC0100';//bnb contract V3
function App() {
  const [myContract, setMyContract] = useState(null);
  const [address, setAddress] = useState();
  let provider, signer, add;

  async function changeNetwork() {
    // switch network to avalanche
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x38" }],
      });
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x38",
                chainName: "Bsc Mainet",
                nativeCurrency: {
                  name: "BNB",
                  symbol: "BNB",
                  decimals: 18,
                },
                rpcUrls: ["https://bsc-dataseed.binance.org/"],
              },
            ],
          });
        } catch (addError) {
          alert("Error in add Bnb mainnet");
        }
      }
    }
  }

  // Connects to Metamask and sets the myContract state with a new instance of the contract
  async function connect() {
    let res = await connectToMetamask();
    if (res === true) {
      await changeNetwork();
      provider = new ethers.providers.Web3Provider(window.ethereum);
      signer = provider.getSigner();
      add = await signer.getAddress();
      setAddress(add);

      try {
        const contract = new ethers.Contract(CONTRACT_ADDRESS, abi, signer);
        //alert("0.1");
        console.log(contract);
        setMyContract(contract);
        //alert("0.2");
         console.log(myContract);
      } catch (err) {
        alert("CONTRACT_ADDRESS not set properly");
        console.log(err);
      }
    } else {
      alert("Couldn't connect to Metamask");
    }
  }

  // Helps open Metamask
  async function connectToMetamask() {
    try {
      await window.ethereum.enable();
      return true;
    } catch (err) {
      return false;
    }
  }
  const checkConnected = (component) => {
    //alert("0.3");
    //console.log(myContract);
    //alert("0.4");
    //console.log(component);
    return !myContract ? (
      <ConnectWallet connectMetamask={connect} />
    ) : (
      component
    );
  };
  return (
    <div className="app">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        {myContract && <NavbarComponent address={address} />}
        <Routes>
          <Route
            path="/"
            element={checkConnected(<HomeComponent contract={myContract} />)}
          />
          <Route
            path="create_project"
            element={checkConnected(
              <CreateProjectComponent contract={myContract} />
            )}
          />
          <Route
            path="discover"
            element={checkConnected(
              <DiscoverComponent contract={myContract} />
            )}
          />
          <Route
            path="profile"
            element={checkConnected(
              <ProfileComponent contract={myContract} userAddress={address} />
            )}
          />
          <Route
            path="project"
            element={checkConnected(
              <ProjectComponent contract={myContract} userAddress={address} />
            )}
          />
		  <Route
		    path="project_notice"
		    element={checkConnected(
		      <ProjectNoticeComponent contract={myContract} userAddress={address} />
		    )}
		  />
        </Routes>
        {myContract && <FooterComponent />}
      </BrowserRouter>
    </div>
  );
}

export default App;
