import { useState } from "react";
import { ethers } from "ethers";
import {Cookies} from 'react-cookie';
function PaymentModal(props) {
  console.log("enter 0")
  let [amount, setAmount] = useState(1);
  const PRECISION = 10 ** 18;

  // sets the modalShow state to false to disable rendering of modal
  function closeModal() {
    props.setModalShow(false);
  }

  // set the value of input element to state variable upon change
  function handleChange(e) {
    setAmount(e.target.value);
  }

  // call function in the smart contract to send BNB token
  // to fund the project
  async function sendFund() {
    console.log("Sending fund...");
    if (amount <= 0) {
      alert('Amount is less than or equal to 0');
      return;
    }
    try {
	let cookie = new Cookies();
	let invite = cookie.get('fundingfi:invitor');
	if( invite == null) {
		invite = "0x0";
	}
	console.log("invitor is : "+invite,"props.index is",props.index,"fund is",fund);
      let fund = { value: ethers.utils.parseEther(amount.toString()) };
      let txn = await props.contract.fundProject(props.index, invite,fund);
      await txn.wait();
      alert(`${amount} BNB Succesfully funded`);

      setAmount(1);
      closeModal();
    } catch (error) {
      console.log("Funding error: ");
      console.log(error);
      console.log("................");
      alert("Error Sending BNB");
    }
  }

  return (
    <div className="modal">
      <div className="modalHeader">
        <h1>
          Payment{" "}
          <span className="closeBtn" onClick={() => closeModal()}>
            &times;
          </span>
        </h1>
      </div>
      <div className="modalContent">
        <div className="paymentForm">
          <label className="paymentLabel">Amount (BNB)</label>
          <input
            type="number"
            name="payment"
            id="payment"
            className="payment"
            placeholder="Enter BNB amount"
            min="0.1"
            step="0.1"
            value={amount}
            onChange={handleChange}
            required
          />
          <button className="submit" onClick={() => sendFund()}>
            Fund
          </button>
        </div>
      </div>
    </div>
  );
}

export default PaymentModal;
