export function getUrlParams(name,str) {
	const reg = new RegExp(`(^|&)${ name}=([^&]*)(&|$)`);
	const r = str.substr(1).match(reg);
	if(r != null) return decodeURIComponent(r[2]); 
	return null;
}

export function getValidIndexs(){
	const array = [0,1,2,3,4,5,6,7];
	return array; 
}
export function getWrittenObj(obj){ 
　　var description = ""; 
　　for(var i in obj){ 
　　　　var property=obj[i]; 
　　　　description+=i+" = "+property+"\n"; 
　　} 
	return description;
}