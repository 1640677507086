import PaymentModal from "./PaymentModal";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import dummyPic from "../assets/pg1.jpg";

function ProjectComponent(props) {
  const [modalShow, setModalShow] = useState(false);
  const [projectDetails, setProjectDetails] = useState({
    amountRaised: 0,
    cid: "",
    creatorName: "",
    fundingGoal: 0,
    projectDescription: "",
    projectName: "",
    contributors: [],
    creationTime: 0,
    duration: 0,
    projectLink: "",
    amount: [],
    creatorAddress: "",
    category: "",
  });
  const [timerString, setTimerString] = useState("");
  const [isOver, setIsOver] = useState(false);
  const location = useLocation();
  const { index } = location.state;
  const PRECISION = 10 ** 18;

  // func to update the progress bar everytime getProjectDetails() executes.
  function updateProgressBar() {
    let progressBar = document.getElementsByClassName("progressBar")[0];
    progressBar.max = projectDetails.fundingGoal / PRECISION;
    progressBar.value = projectDetails.amountRaised / PRECISION;
  }

  // fetch the project details from the smart contract
  async function getProjectDetails() {
    try {
      // fetching project information from the contract
     let res = await fetch('https://fundingfi.io/api/apiproj.php?op=getProjectDescription&id='+parseInt(index)) // 这里的URL需要根据实际情况进行修改
     .then(response => response.json()).then((res) =>  {
        let {
          amountRaised,
          cid,
          creatorName,
          fundingGoal,
          projectDescription,
          projectName,
          contributors,
          creationTime,
          duration,
          projectLink,
          amount,
          creatorAddress,
          refundPolicy,
          category,
          refundClaimed,
          claimedAmount,
        } = { ...res[0] };        

        let tmp = [];
        for (const index in contributors) {
          tmp.push({
            contributor: contributors[index],
            amount: amount[index],
            refundClaimed: refundClaimed[index]
          });
        }

        tmp.sort((a, b) => {return (b.amount - a.amount)});
        
        let contributorsCopy = [];
        let amountCopy = [];
        let refundClaimedCopy = [];
        for (const index in tmp) {
          contributorsCopy.push(tmp[index].contributor);
          amountCopy.push(tmp[index].amount);
          refundClaimedCopy.push(tmp[index].refundClaimed);
        }

        setProjectDetails({
          amountRaised: amountRaised,
          cid: cid,
          creatorName: creatorName,
          fundingGoal: fundingGoal,
          projectDescription: projectDescription,
          projectName: projectName,
          contributors: contributorsCopy,
          creationTime: creationTime * 1,
          duration: duration,
          projectLink: projectLink,
          amount: amountCopy,
          creatorAddress: creatorAddress,
          refundPolicy: refundPolicy,
          category: category,
          refundClaimed: refundClaimedCopy,
          claimedAmount: claimedAmount,
        });
      });
    } catch (error) {
      //alert("Error fetching details");
      console.log(error);
    }
  }

  useEffect(() => {
    getProjectDetails();
  }, []);

  useEffect(() => {
    getProjectDetails();
  }, [modalShow]);

  // useEffect hook to handle the countdown timer
  useEffect(() => {
    const interval = setInterval(() => {
      const currentTime = new Date().getTime() / 1000;
      const remainingTime =
        Number(projectDetails.creationTime) +
        Number(projectDetails.duration) -
        currentTime;
      const days = Math.floor(remainingTime / (60 * 60 * 24));
      const hours = Math.floor((remainingTime % (60 * 60 * 24)) / (60 * 60));
      const minutes = Math.floor((remainingTime % (60 * 60)) / 60);
      const seconds = Math.floor(remainingTime % 60);

      setTimerString(`${days}d ${hours}h ${minutes}m ${seconds}s`);

      /*if (remainingTime < 0) {
        setTimerString("0d 0h 0m 0s");
        clearInterval(interval);
        // this condition is set because at initial render, creationTime and duration state are not set
        // so remaining time turns out to be negative
        if (projectDetails.creationTime > 0) {
          setIsOver(true);
        }
      }*/
	  setIsOver(true);
    }, 1000);

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [projectDetails.creationTime, projectDetails.duration]);

  useEffect(() => {
    updateProgressBar();
  }, [projectDetails]);

  // sets the condition true for payment modal to render 
  function onClickPayment() {
    setModalShow(true);
  }

  // return category code
  function getCategoryFromCode(val) {
    let categoryCode = ["Design & Tech", "Film", "Arts", "Games"];
    if (val >= 0 && val < 4) return categoryCode[val];
  }

  // convert epoch time format to dd/mm/yyyy format
  function displayDate(val) {
    let date = new Date(val * 1000);
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  }


  // check if user is the project owner
  function isOwner() {
      return (props.userAddress === projectDetails.creatorAddress);
  }

  // check if claiming fund is possible for the project owner
  function claimFundCheck() {
    return (projectDetails.refundPolicy ? (projectDetails.amountRaised / PRECISION) : (projectDetails.amountRaised >= projectDetails.fundingGoal));
  }


  // claim fund by calling function in the smart contract
  async function claimFund() {
      let txn;
      try {
        txn = await props.contract.claimFund(parseInt(index));
        await txn.wait(txn);
        alert('Fund succesfully claimed');

        setProjectDetails({
            amountRaised: projectDetails.amountRaised,
            cid: projectDetails.cid,
            creatorName: projectDetails.creatorName,
            fundingGoal: projectDetails.fundingGoal,
            projectDescription: projectDetails.projectDescription,
            projectName: projectDetails.projectName,
            contributors: projectDetails.contributors,
            creationTime: projectDetails.creationTime * 1,
            duration: projectDetails.duration,
            projectLink: projectDetails.projectLink,
            amount: projectDetails.amount,
            creatorAddress: projectDetails.creatorAddress,
            refundPolicy: projectDetails.refundPolicy,
            category: projectDetails.category,
            refundClaimed: projectDetails.refundClaimed,
            claimedAmount: true
          });

      }catch(error) {
        alert('Error claiming fund: ' + error);
        console.log(error);
      }    
  }

  // check if the user is a contributor to the project
  function checkIfContributor() {
      let idx = getContributorIndex();
      return ((idx < 0) ? false : true);
  }

  // get the contributor index of the user in the contributor[]
  function getContributorIndex() {
      let idx = projectDetails.contributors.indexOf(props.userAddress);
      return idx;
  }

  // check if claiming refund is possible for the user
  function claimRefundCheck() {
      return (projectDetails.refundPolicy ? false : (projectDetails.amountRaised < projectDetails.fundingGoal));
  }

  // claim refund by calling the function in the smart contract
  async function claimRefund() {
      let txn;
      try {
        txn = await props.contract.claimRefund(parseInt(index));
        await txn.wait(txn);
        alert('Refund claimed succesfully');
        let refundClaimedCopy = [...projectDetails.refundClaimed];
        refundClaimedCopy[getContributorIndex()] = true;

        setProjectDetails({
            amountRaised: projectDetails.amountRaised,
            cid: projectDetails.cid,
            creatorName: projectDetails.creatorName,
            fundingGoal: projectDetails.fundingGoal,
            projectDescription: projectDetails.projectDescription,
            projectName: projectDetails.projectName,
            contributors: projectDetails.contributors,
            creationTime: projectDetails.creationTime * 1,
            duration: projectDetails.duration,
            projectLink: projectDetails.projectLink,
            amount: projectDetails.amount,
            creatorAddress: projectDetails.creatorAddress,
            refundPolicy: projectDetails.refundPolicy,
            category: projectDetails.category,
            refundClaimed: refundClaimedCopy,
            claimedAmount: true
          });

      }catch(error) {
          alert('Error claiming refund: ' + error);
          console.log(error);
      }
  }

  return (
    <>
      <div>
      
      
          <section class="project-details-area pt-120 pb-190">
              <div class="container">
                  <div class="row">
                      <div class="col-lg-7">
                          <div class="project-details-thumb">
                              <img
                                src={
                                  projectDetails.cid ? "https://" + projectDetails.cid : dummyPic
                              	
                                }
                                alt="test-pic"
                              />
                              <div class="icon">
                                  <i class="fa fa-heart"></i>
                              </div>
                          </div>
                      </div>
                      <div class="col-lg-5">
                          <div class="project-details-content">
                              <div class="details-btn">
                                  <span>{getCategoryFromCode(projectDetails.category)}</span>
                                  
                              </div>
                              <h3 class="title">{projectDetails.projectName}</h3>
                              <div class="project-details-item">
                                  <div class="item text-center">
                                      <h5 class="title"> -- BNB</h5>
                                      <span>Pledged</span>
                                  </div>
                                  <div class="item text-center">
                                      <h5 class="title">--</h5>
                                      <span>Backers</span>
                                  </div>
                                  <div class="item text-center">
                                      <h5 class="title">{!isOver ? timerString : "Coming soon"}</h5>
                                      <span>Time left</span>
                                  </div>
                              </div>
                              <div class="projects-range">
                                  <div class="projects-range-content">
                                      <ul>
                                          <li>Raised:</li>
                                          <li>--BNB</li>
                                      </ul>
                                      <div className="progressBarContainer">
                                        <progress
                                          min="0"
                                          max="100"
                                          value="30"
                                          className="progressBar"
                                        ></progress>
                                      </div>
                                  </div>
                              </div>
                              <div class="projects-goal">
                                  <span>Goal: <span>{projectDetails.fundingGoal / PRECISION} BNB</span></span>
                              </div>
                              {!isOver ? (!isOwner() && (
                              <div className="supportButtonContainer">
                              <button
                                className="supportButton"
                                onClick={() => onClickPayment()}
                              >
                                Back this project
                              </button>
                              </div>
                              )) : isOwner() ? ((claimFundCheck() && !projectDetails.claimedAmount) ? (
                              <div className="supportButtonContainer">
                                  <button
                                    className="supportButton"
                                    onClick={() => claimFund()}
                                  >
                                    Claim Fund
                                  </button>
                                </div>
                              ) : (projectDetails.claimedAmount ? (<h2 style={ { color: 'red' } }>Fund claimed!</h2>) : '')) : (
                                  (checkIfContributor() && claimRefundCheck() && !projectDetails.refundClaimed[getContributorIndex()]) ?
                                  (<div className="supportButtonContainer">
                                  <button
                                    className="supportButton"
                                    onClick={() => claimRefund()}
                                  >
                                    Claim Refund
                                  </button>
                                </div>
                              ) : (projectDetails.refundClaimed[getContributorIndex()] ? (<h2 style={{ color: 'red' }}>Refund Claimed!</h2>) : ''))}
                              {modalShow && (
                                <PaymentModal
                                  setModalShow={setModalShow}
                                  contract={props.contract}
                                  index={index}
                                  projectDetails={projectDetails}
                                  setProjectDetails={setProjectDetails}
                                  userAddress={props.userAddress}
                                />
                              )}
                              <div class="project-share d-flex align-items-center">
                                  <span>Share this Project</span>
                                  <ul>
                                      <li><a href="#"><i class="fa fa-facebook-square"></i></a></li>
                                      <li><a href="#"><i class="fa fa-twitter"></i></a></li>
                                      <li><a href="#"><i class="fa fa-pinterest-p"></i></a></li>
                                      <li><a href="#"><i class="fa fa-instagram"></i></a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          
         
          
          <section class="project-details-content-area pb-110">
              <div class="container">
                  <div class="row justify-content-center">
                      <div class="col-lg-8">
                          <div class="tab-btns">
                              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                  <li class="nav-item" role="presentation">
                                      <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Details</a>
                                  </li>
                                  
                              </ul>
                          </div>
                          <div class="tab-content" id="pills-tabContent">
                              <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                  <div class="project-details-content-top">
                                      <p>{projectDetails.projectDescription}</p>
                                      <ul>
                                          <li><i class="flaticon-check"></i>Project link:{projectDetails.projectLink}c</li>
                                          <li><i class="flaticon-check"></i>Creator :{" " + projectDetails.creatorName}</li>
                                          <li><i class="flaticon-check"></i>Get ordinals after 5 days after funding finished </li>
                                         
                                      </ul>
                                      
                                  </div>
                                  
                              </div>
                              
                          </div>
                      </div>
                      
                  </div>
              </div>
          </section>
          
         
          </div>
    </>
  );
}

export default ProjectComponent;
